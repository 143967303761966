// import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
// import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { STORAGE_KEYS } from "../../../const";
const navConfig = [
  // {
  //   name: "Dashboard",
  //   path: "/console/dashboard",
  //   icon: DashboardOutlinedIcon,
  // },
  {
    name: "Orders",
    path: "/console/orders",
    icon: ShoppingCartOutlinedIcon,
  },
  {
    name: "Categories",
    path: "/console/categories",
    icon: CategoryOutlinedIcon,
  },
  // {
  //   name: "Report",
  //   path: "/console/report",
  //   icon: SummarizeOutlinedIcon,
  // },
  {
    name: "Settings",
    path: "/console/settings",
    icon: SettingsOutlinedIcon,
  },
  {
    name: "Logout",
    path: "/user/login",
    icon: LogoutRoundedIcon,
    clickHandler: () => {
      localStorage.removeItem(STORAGE_KEYS.TOKEN);
      localStorage.removeItem(STORAGE_KEYS.SHOP_ID);
      window.location.reload();
    },
  },
];
export default navConfig;
