import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ConfirmPopup(props) {
  const {
    data,
    handleClose,
    handleSuccess,
    alertTitle,
    alertContent,
    successBtnName,
  } = props;
  return (
    <Dialog
      open={Boolean(data)}
      onClose={() => {
        handleClose("");
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {alertContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose("");
          }}
          color="primary"
        >
          cancel
        </Button>
        <Button
          onClick={() => {
            handleSuccess(data);
            handleClose("");
          }}
          color="primary"
          autoFocus
        >
          {successBtnName}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
