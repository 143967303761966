import { useState, useEffect, useContext, useCallback } from "react";
import styles from "./styles.module.css";
import OrderTrack from "./OrderTrack";
import { DragDropContext } from "react-beautiful-dnd";
import loadingContext from "../../../context/loadingContext";
import orderService from "../../../services/orderService";
import OpenOrderDetail from "../../popups/OpenOrderDetail";

const tracks = [
  "pending",
  "processing",
  "preparing",
  "packing",
  "dispatched",
  "completed",
];

export default function Orders() {
  const { setIsLoading } = useContext(loadingContext);
  const [orders, setOrders] = useState({});
  const [selectedOrder, setSelectedOrder] = useState(null);

  // track updater
  const updateTrack = (id, source, destination) => {
    const order = orders[source].find((order) => order.id === id);
    const updatedDestination = orders[destination]
      ? [...orders[destination], order]
      : [order];
    const updatedSource = [
      ...orders[source].filter((order) => order.id !== id),
    ];
    return setOrders({
      ...orders,
      [source]: updatedSource,
      [destination]: updatedDestination,
    });
  };

  const getOrders = useCallback(async () => {
    try {
      setIsLoading(true);
      const orders = await orderService.getOrders();
      setOrders(orders);
    } catch (err) {
      console.error(err.message);
    }
    setIsLoading(false);
  }, [setIsLoading, setOrders]);
  //To handle drag movements
  const dragEndHandler = async (result) => {
    try {
      updateTrack(
        result.draggableId,
        result.source.droppableId,
        result.destination.droppableId
      );
      const data = {
        status: result.destination.droppableId,
      };
      const id = result.draggableId;
      await orderService.updateOrder(id, data);
      getOrders();
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getOrders();
  }, [getOrders]);

  return (
    <>
      <div className={styles.titleBarWrapper}>
        <div className={styles.title}>Orders</div>
      </div>
      <div className={styles.orderWrapper}>
        <DragDropContext onDragEnd={dragEndHandler}>
          {tracks.map((track, index) => (
            <OrderTrack
              key={index}
              title={track}
              elements={orders[track] ? orders[track] : []}
              setSelectedOrder={setSelectedOrder}
            />
          ))}
        </DragDropContext>
      </div>
      {selectedOrder && (
        <OpenOrderDetail
          order={selectedOrder}
          handleClose={() => {
            setSelectedOrder(null);
          }}
        />
      )}
    </>
  );
}
