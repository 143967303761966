import axios from "axios";
import { BACKEND_URL, STORAGE_KEYS, HEADERS } from "../const";

const get = async (path, headers = {}, params = {}) => {
  const url = `${BACKEND_URL}${path}`;
  const response = await axios.get(url, _generateParams(headers, params));
  return response.data;
};

const post = async (path, data = {}, headers = {}, params = {}) => {
  const url = `${BACKEND_URL}${path}`;

  const response = await axios.post(
    url,
    data,
    _generateParams(headers, params)
  );

  return response.data;
};

const patch = async (path, data = {}, headers = {}, params = {}) => {
  const url = `${BACKEND_URL}${path}`;

  const response = await axios.patch(
    url,
    data,
    _generateParams(headers, params)
  );

  return response.data.data;
};

const put = async (path, data = {}, headers = {}, params = {}) => {
  const url = `${BACKEND_URL}${path}`;

  const response = await axios.put(url, data, _generateParams(headers, params));

  return response.data.data;
};

const destroy = async (path, headers = {}, params = {}) => {
  const url = `${BACKEND_URL}${path}`;

  const response = await axios.delete(url, _generateParams(headers, params));

  return response.data;
};

const _generateParams = (headers = {}, params = {}) => {
  const authToken = localStorage.getItem(STORAGE_KEYS.TOKEN);

  if (!params.shopId) {
    const shopId = localStorage.getItem(STORAGE_KEYS.SHOP_ID);
    params.shopId = shopId;
  }

  return {
    headers: {
      [HEADERS.AUTH]: authToken,
      ...headers,
    },
    params,
  };
};

const BackendService = {
  get,
  post,
  patch,
  put,
  destroy,
};

export default BackendService;
