import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import EastSharpIcon from "@mui/icons-material/EastSharp";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./styles.module.css";
import { Breadcrumbs, Link } from "@mui/material";
import ItemCard from "../../utils/ItemCard";
import AddItem from "../../popups/AddItem";
import itemService from "../../../services/itemService";
import loadingContext from "../../../context/loadingContext";
import categoryService from "../../../services/categoryService";
import Info from "../../utils/Alert/Info";
import ConfirmPopup from "../../utils/Alert/ConfirmPopup";

export default function CategoryItem() {
  const { setIsLoading } = useContext(loadingContext);
  const { id } = useParams();
  const [isAddItemOpen, setIsAddItemOpen] = useState(false);
  const [categoryDetails, setCategoryDetails] = useState();
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const openAddItem = () => setIsAddItemOpen(true);
  const closeAddItem = () => {
    setSelectedItem(null);
    setIsAddItemOpen(false);
  };
  const editHandler = (item) => {
    setSelectedItem(item);
    openAddItem();
  };
  const dltDialogToggler = (id) => setDeleteItem(id ? id : null);
  const deleteHandler = async (id) => {
    setIsLoading(true);
    try {
      await itemService.deleteItem(id);
      getItems();
    } catch (err) {
      console.error(err.message);
    }
    setIsLoading(false);
  };

  const [items, setItems] = useState();

  useEffect(() => {
    const getItems = async () => {
      setIsLoading(true);
      try {
        const items = await itemService.getItemsByCategory(id);
        setItems(items);
      } catch (err) {
        console.error(err.message);
      }
      setIsLoading(false);
    };
    const getCategoryDetails = async () => {
      try {
        const categoryDetails = await categoryService.getCategory(id);
        setCategoryDetails(categoryDetails);
      } catch (err) {
        console.error(err.message);
      }
    };
    getCategoryDetails();
    getItems();
  }, [id, setIsLoading]);

  const getItems = async () => {
    setIsLoading(true);
    try {
      const items = await itemService.getItemsByCategory(id);
      setItems(items);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className={styles.titleBarWrapper}>
        <div className={styles.titleWrapper}>
          {categoryDetails && (
            <>
              <span className={styles.title}>{categoryDetails?.name}</span>
              <Breadcrumbs
                separator={<EastSharpIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link
                  href="/console/categories"
                  underline="hover"
                  key="1"
                  className={styles.link}
                >
                  Categories
                </Link>
                <span key="2" className={styles.activeLink}>
                  {categoryDetails?.name}
                </span>
              </Breadcrumbs>
            </>
          )}
        </div>
        <div className={styles.filterWrapper}>
          <div className={styles.searchBarWrapper}>
            <SearchIcon className={styles.searchIcon} />
            <input
              type="text"
              className={styles.searchBar}
              placeholder="Search"
            />
          </div>
          <span className={styles.coloredBtn} onClick={openAddItem}>
            Add New Item
          </span>
          <AddItem
            isOpen={isAddItemOpen}
            closeHandler={closeAddItem}
            categoryId={id}
            getItems={getItems}
            selectedItem={selectedItem}
          />
          <ConfirmPopup
            data={deleteItem}
            alertTitle="Do you want to delete this item?"
            alertContent="You can't retrieve the deleted product"
            successBtnName="Delete"
            handleClose={dltDialogToggler}
            handleSuccess={deleteHandler}
          />
        </div>
      </div>
      <div className={styles.cardsWrapper}>
        {items && !items.length ? (
          <Info
            title="No Items Found"
            content="This category does not have an item to list"
          />
        ) : null}
        {items?.map((item) => (
          <ItemCard
            key={item.id}
            content={item}
            editHandler={editHandler}
            deleteHandler={dltDialogToggler}
          />
        ))}
      </div>
    </>
  );
}
