import { useEffect, useState, useContext, useCallback } from "react";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./styles.module.css";
import ItemCard from "../../utils/ItemCard";
import AddCategory from "../../popups/AddCategory";
import categoryService from "../../../services/categoryService";
import loadingContext from "../../../context/loadingContext";
import Info from "../../utils/Alert/Info";
import ConfirmPopup from "../../utils/Alert/ConfirmPopup";

export default function CategoryList() {
  const { setIsLoading } = useContext(loadingContext);
  const [categories, setCategories] = useState();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  // To toggle add/edit popup view
  const openAddCategory = () => setSelectedCategory({});
  const closeAddCategory = () => {
    setSelectedCategory(null);
  };
  const onCategoryActionSuccess = () => {
    getCategories().then();
  };
  const editHandler = (category) => {
    setSelectedCategory(category);
  };
  // To toggle delete cofirm dialog
  const dltDialogToggler = (id) => setDeleteItem(id ? id : null);

  const deleteCategory = async (id) => {
    setIsLoading(true);
    try {
      await categoryService.deleteCategory(id);
      await getCategories();
    } catch (err) {
      console.error(err.message);
    }
    setIsLoading(false);
  };
  const getCategories = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await categoryService.getCategories();
      setCategories(res);
    } catch (err) {
      console.error(err.message);
    }
    setIsLoading(false);
  }, [setIsLoading]);

  useEffect(() => {
    getCategories().then();
  }, [setIsLoading, getCategories]);

  return (
    <>
      <div className={styles.titleBarWrapper}>
        <div className={styles.titleWrapper}>
          <span className={styles.title}>Categories</span>
        </div>
        <div className={styles.filterWrapper}>
          <div className={styles.searchBarWrapper}>
            <SearchIcon className={styles.searchIcon} />
            <input
              type="text"
              className={styles.searchBar}
              placeholder="Search"
            />
          </div>
          <span className={styles.coloredBtn} onClick={openAddCategory}>
            Add New Category
          </span>
          {selectedCategory && (
            <AddCategory
              category={selectedCategory}
              closeHandler={closeAddCategory}
              onSuccess={onCategoryActionSuccess}
            />
          )}
          <ConfirmPopup
            data={deleteItem}
            alertTitle="Do you want to delete?"
            alertContent="You can't retrieve the deleted categories"
            successBtnName="Delete"
            handleClose={dltDialogToggler}
            handleSuccess={deleteCategory}
          />
        </div>
      </div>
      <div className={styles.cardsWrapper}>
        {categories && !categories.length ? (
          <Info
            title="No Category Items Found"
            content="This shop does not have any categories to list"
          />
        ) : (
          categories?.map((category) => (
            <ItemCard
              key={category.id}
              content={category}
              link={`/console/categories/${category.id}`}
              editHandler={editHandler}
              deleteHandler={dltDialogToggler}
            />
          ))
        )}
      </div>
    </>
  );
}
