import styles from "./styles.module.css";
import PopUpLayout from "../utils/Layout/PopUpLayout";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";

export default function OrderDetailsPopup({ order, handleClose }) {
  return (
    <>
      <div className="no-print">
        <PopUpLayout>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span className={styles.title}>Order Details</span>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
          </div>
          <OrderDetails order={order} />
        </PopUpLayout>
      </div>
      <div className="print print-layout">
        <OrderDetailsPrint order={order} />
      </div>
    </>
  );
}

function OrderDetails({ order }) {
  return (
    <>
      <div className={styles.detaildiv}>
        <div className={styles.titlediv}>
          <h3 className={styles.ordertitle}>Order Id</h3>
          <h3 className={styles.ordertitle}>Order Status</h3>
        </div>
        <div>
          <h3 className={styles.orderdetail}>{order.id}</h3>
          <h3 className={styles.orderdetail}>{order.status}</h3>
        </div>
        {order.customer && (
          <>
            {order.customer.map((label) => {
              return (
                <div className={styles.titlediv}>
                  <h3 className={styles.ordertitle}>{label}</h3>
                </div>
              );
            })}
            {order.customer.map((value) => {
              return (
                <div>
                  <h3 className={styles.orderdetail}>{value}</h3>
                </div>
              );
            })}
          </>
        )}
      </div>
      {order.products && (
        <div>
          <span className={styles.title}>Product Details</span>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell align="right">Category</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.products.map((orderProduct) => (
                  <TableRow
                    key={orderProduct.product.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {orderProduct.product.name}
                    </TableCell>
                    <TableCell align="right">
                      {orderProduct.product.category}
                    </TableCell>
                    <TableCell align="right">{orderProduct.quantity}</TableCell>
                    <TableCell align="right">
                      {orderProduct.product.price}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
}

// TODO: Optimise design for print
function OrderDetailsPrint({ order }) {
  return (
    <>
      <div className={styles.detaildiv}>
        <div className={styles.titlediv}>
          <h3 className={styles.ordertitle}>Name</h3>
          <h3 className={styles.ordertitle}>Mobile</h3>
          <h3 className={styles.ordertitle}>Description</h3>
          <h3 className={styles.ordertitle}>Address</h3>
        </div>
        <div>
          <h3 className={styles.orderdetail}>{order.name}</h3>
          <h3 className={styles.orderdetail}>{order.mobile}</h3>
          <h3 className={styles.orderdetail}>{order.description}</h3>
          <h3 className={styles.orderdetail}>{order.address}</h3>
        </div>
      </div>
      {order.products && (
        <div>
          <div className={styles.title}>Product Details</div>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell align="right">Category</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.products.map((orderProduct) => (
                  <TableRow
                    key={orderProduct.product.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {orderProduct.product.name}
                    </TableCell>
                    <TableCell align="right">
                      {orderProduct.product.category}
                    </TableCell>
                    <TableCell align="right">{orderProduct.quantity}</TableCell>
                    <TableCell align="right">
                      {orderProduct.product.price}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
}
