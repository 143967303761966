import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ConsoleLayout from "./components/utils/Layout/ConsoleLayout";
import UserLayout from "./components/utils/Layout/UserLayout";
import CategoryItem from "./components/pages/Categories/CategoryItem";
import CategoryList from "./components/pages/Categories/CategoryList";
import Login from "./components/pages/user/Login";
import Settings from "./components/pages/settings";
import Orders from "./components/pages/orders";
import { STORAGE_KEYS } from "./const";
import { LoadingProvider } from "./context/loadingContext";

function App() {
  return (
    <LoadingProvider>
      <Router>
        <Routes>
          <Route path="/console" element={<ConsoleLayout />}>
            <Route path="categories" element={<CategoryList />} />
            <Route path="categories/:id" element={<CategoryItem />} />
            <Route path="orders" element={<Orders />} />
            <Route path="settings" element={<Settings />} />
          </Route>
          <Route path="/user" element={<UserLayout />}>
            <Route path="login" element={<Login />} />
          </Route>
          <Route
            path="/"
            element={
              localStorage.getItem(STORAGE_KEYS.TOKEN) ? (
                <Navigate to="/console/orders" />
              ) : (
                <Navigate to="/user/login" />
              )
            }
          />
        </Routes>
      </Router>
    </LoadingProvider>
  );
}

export default App;
