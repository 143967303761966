import BackendService from "./BackendService";

const addCategory = (data) => {
  return BackendService.post(`/categories`, data);
};

const getCategories = () => {
  return BackendService.get(`/categories`);
};

const getCategory = (id) => {
  return BackendService.get(`/categories/${id}`);
};

const updateCategory = (id, data) => {
  return BackendService.patch(`/categories/${id}`, data);
};

const deleteCategory = (id) => {
  return BackendService.destroy(`/categories/${id}`);
};

const addCategoryImage = (id, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return BackendService.post(`/categories/${id}/image`, formData);
};

const categoryService = {
  addCategory,
  getCategories,
  getCategory,
  updateCategory,
  deleteCategory,
  addCategoryImage,
};

export default categoryService;
