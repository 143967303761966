import BackendService from "./BackendService";

const getOrders = () => BackendService.get("/orders");
const updateOrder = (id, data) => BackendService.patch(`/orders/${id}`, data);

const orderService = {
  getOrders,
  updateOrder,
};

export default orderService;
