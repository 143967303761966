import { Outlet } from "react-router-dom";
import styles from "./styles.module.css";
import Logo from "./logo.png";
import brandLogo from "./brand.png";
import Loader from "../Loader";
export default function UserLayout() {
  return (
    <div className={styles.userWrapper}>
      <Loader />
      <div className={styles.logoWrapper}>
        <div className={styles.logoContainer}>
          <img src={Logo} className={styles.logoImg} alt="" />
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.contentContainer}>
          <img src={brandLogo} className={styles.brandLogo} alt="" />
          <div className={styles.outletWrapper}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
