import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import Logo from "./logo.png";
import navConfig from "./navConfig";
import { useLocation, matchPath } from "react-router-dom";
export default function NavBar() {
  const { pathname } = useLocation();

  //To match current url and nav url
  const match = (path) =>
    path ? matchPath({ path, end: false }, pathname) : false;

  return (
    <>
      <div className={styles.brandNameWrapper}>
        <img src={Logo} className={styles.logo} alt="" />
        <span className={styles.brandName}>Eazy cart</span>
      </div>
      {navConfig.map((nav) => (
        <Link
          key={nav.name}
          className={`${styles.navLinkWrapper} ${
            match(nav.path) ? styles.activeNavLink : null
          }`}
          to={nav.path}
          onClick={nav.clickHandler}
        >
          <nav.icon className={styles.navLinkIcon} />
          <span className={styles.navLink}>{nav.name}</span>
        </Link>
      ))}
    </>
  );
}
