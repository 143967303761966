import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import item from "./item.png";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

export default function ItemCard({
  content,
  link,
  editHandler,
  deleteHandler,
}) {
  const navigate = useNavigate();
  const { id, name, description, price, image } = content;
  const redirectToEdit = (id) => () => editHandler(content);
  const redirectToDelete = (id) => () => deleteHandler(id);
  const navigateToNextPage = () => navigate(link);
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.actionWrapper}>
        <EditIcon
          fontSize="small"
          color="disabled"
          onClick={redirectToEdit(id)}
        />
        <DeleteIcon
          fontSize="small"
          color="disabled"
          onClick={redirectToDelete(id)}
        />
      </div>
      <img
        src={image ? image : item}
        alt={name}
        className={styles.image}
        onClick={navigateToNextPage}
      />
      {name && (
        <span onClick={navigateToNextPage} className={styles.title}>
          {name}
        </span>
      )}
      {description && <span className={styles.disc}>{description}</span>}
      {price && <span className={styles.price}>₹{price}</span>}
    </div>
  );
}
