import { Alert, AlertTitle } from "@material-ui/lab";
export default function Info(props) {
  const { title, content } = props;
  return (
    <Alert severity="info" style={{ marginTop: "15px", width: "100%" }}>
      <AlertTitle>
        <strong>{title}</strong>
      </AlertTitle>
      {content && content}
    </Alert>
  );
}
