import { createContext, useState } from "react";
const loadingContext = createContext();
export default loadingContext;

export function LoadingProvider(props) {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <loadingContext.Provider value={{ isLoading, setIsLoading }}>
      {props.children}
    </loadingContext.Provider>
  );
}
