import styles from "./styles.module.css";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { isUrl } from "../../../helpers/commonHelperFunctions";

export default function ImageUpload({ formik }) {
  const imageOnchangeHandler = (event) => {
    return formik.setFieldValue("image", event.currentTarget.files[0]);
  };

  return (
    <div className={styles.uploadWrapper}>
      <div className={styles.uploadSection}>
        {formik.values["image"] ? (
          <img
            src={
              formik.values["image"] && isUrl(formik.values["image"])
                ? formik.values["image"]
                : URL.createObjectURL(formik.values["image"])
            }
            className={styles.productImage}
            alt=""
          />
        ) : (
          <>
            <CloudUploadOutlinedIcon className={styles.uploadIcon} />
            <span className={styles.message}>Upload the image...</span>
          </>
        )}
      </div>

      <input
        id="upload-image"
        type="file"
        hidden
        onChange={imageOnchangeHandler}
      />

      <label htmlFor="upload-image" className={styles.uploadBtn}>
        Upload Image
      </label>
    </div>
  );
}
