import { useContext, useEffect } from "react";
import styles from "./styles.module.css";
import PopUpLayout from "../utils/Layout/PopUpLayout";
import TextInput from "../utils/TextInput";
import { useFormik } from "formik";
import * as yup from "yup";
import ImageUpload from "../utils/ImageUpload";
import itemService from "../../services/itemService";
import loadingContext from "../../context/loadingContext";
import { isUrl } from "../../helpers/commonHelperFunctions";

export default function AddItem({
  isOpen,
  closeHandler,
  categoryId,
  getItems,
  selectedItem,
}) {
  const { setIsLoading } = useContext(loadingContext);
  const initialValues = {
    image: "",
    name: "",
    desc: "",
    price: "",
  };
  const validationSchema = yup.object({
    image: yup.mixed(),
    name: yup.string().required("Name is required"),
    desc: yup.string().required("Description is required"),
    price: yup
      .string()
      .min(0, "Price must be greater than 0")
      .required("Price is required"),
  });

  async function submitHandler(value, { setFieldError, resetForm }) {
    setIsLoading(true);
    try {
      const data = {
        name: value.name,
        description: value.desc,
        price: value.price,
        categoryId,
      };
      const item = await itemService.addItem(data);
      if (value.image) {
        await itemService.addItemImage(item.id, value.image);
      }

      resetForm();
      getItems();
      closePopUp();
    } catch (err) {
      console.error(err.message);
      setFieldError("name", err.message);
    }
    setIsLoading(false);
  }
  const editHandler = async (value, { setFieldError, resetForm }) => {
    setIsLoading(true);
    try {
      const data = {
        name: value.name,
        description: value.desc,
        price: value.price,
        categoryId,
      };
      const item = await itemService.updateItem(selectedItem.id, data);
      if (!isUrl(value.image)) {
        await itemService.addItemImage(item.id, value.image);
      }
      resetForm();
      getItems();
      closePopUp();
    } catch (err) {
      console.error(err.message);
      setFieldError("name", err.message);
    }
    setIsLoading(false);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: selectedItem ? editHandler : submitHandler,
    validateOnChange: false,
  });
  const closePopUp = () => {
    formik.setValues({
      image: "",
      name: "",
      desc: "",
      price: "",
    });
    closeHandler();
  };
  useEffect(() => {
    if (!selectedItem) {
      return;
    }

    formik.setValues({
      name: selectedItem.name,
      desc: selectedItem.description,
      price: selectedItem.price,
      image: selectedItem.image,
    });
  }, [selectedItem]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    isOpen && (
      <PopUpLayout>
        <div className={styles.title}>Add Item</div>
        <ImageUpload formik={formik} />
        <TextInput
          name="name"
          label="Item Name"
          placeholder="Type your Item name"
          formikObj={formik}
        />
        <TextInput
          label="Description"
          name="desc"
          placeholder="Type your product description"
          formikObj={formik}
        />
        <TextInput
          name="price"
          label="Price"
          placeholder="Type Product price"
          formikObj={formik}
        />
        <div className={styles.btnWrapper}>
          <button className={styles.closeBtn} onClick={closePopUp}>
            Cancel
          </button>
          <button className={styles.submitBtn} onClick={formik.handleSubmit}>
            Submit
          </button>
        </div>
      </PopUpLayout>
    )
  );
}
