import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import TextInput from "../../utils/TextInput";
import { useFormik } from "formik";
import * as yup from "yup";
import authService from "../../../services/authService";
import { STORAGE_KEYS } from "../../../const";
import profileService from "../../../services/profileService";
import loadingContext from "../../../context/loadingContext";
import { useContext } from "react";

export default function Login() {
  const { setIsLoading } = useContext(loadingContext);
  const navigate = useNavigate();
  const initialValues = {
    Username: "",
    Password: "",
  };
  const validationSchema = yup.object({
    Username: yup.string().required("Username is required"),
    Password: yup
      .string()
      .required("Password is required")
      .max(18)
      .min(4, "Password must be at least 4 characters"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitHandler,
    validateOnChange: false,
  });

  async function submitHandler(value, { setFieldError }) {
    setIsLoading(true);
    try {
      const data = { username: value.Username, password: value.Password };
      localStorage.removeItem(STORAGE_KEYS.SHOP_ID);
      const response = await authService.loginUser(data);
      await localStorage.setItem(STORAGE_KEYS.TOKEN, response.token);
      const profileRes = await profileService.getProfile();
      await localStorage.setItem(STORAGE_KEYS.SHOP_ID, profileRes.shops[0]);
      navigate("/console/categories");
      window.location.reload();
    } catch (err) {
      console.error(err);
      setFieldError("Username", "Username or password is incorrect");
    }
    setIsLoading(false);
  }
  return (
    <>
      <h2 className={styles.title}>Login</h2>
      <TextInput
        name="Username"
        placeholder="Type your username"
        formikObj={formik}
      />
      <TextInput
        type="Password"
        name="Password"
        placeholder="Type your password"
        formikObj={formik}
      />
      <Link to="/user/login" className={styles.link}>
        Forgot Password?
      </Link>
      <button
        className={styles.btn}
        onClick={formik.handleSubmit}
        type="submit"
      >
        Login
      </button>
    </>
  );
}
