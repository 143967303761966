import BackendService from "./BackendService";

const loginUser = async (data) => {
  return BackendService.post("/auth/login", data);
};
const authService = {
  loginUser,
};

export default authService;
