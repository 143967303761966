export const BACKEND_URL = "https://sanoft-eazy-cart.uc.r.appspot.com/api/user";

export const STORAGE_KEYS = {
  TOKEN: "x-auth-token",
  SHOP_ID: "x-shop-id",
};
export const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];
export const FILE_SIZE = 5000000;

export const HEADERS = {
  AUTH: "Authorization",
};
