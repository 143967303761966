import { STORAGE_KEYS } from "../../const";
import profileService from "../../services/profileService";
export default function useAuthUser() {
  const shopId = localStorage.getItem(STORAGE_KEYS.SHOP_ID);
  const shop = shopId ? shopId : getShop();
  const token = localStorage.getItem(STORAGE_KEYS.TOKEN);
  async function getShop() {
    try {
      const res = await profileService.getProfile();
      localStorage.setItem(STORAGE_KEYS.SHOP_ID, res.shops[0]);
      return res.shops[0];
    } catch (err) {
      console.error(err.message);
      return 0;
    }
  }
  return token && shop ? true : false;
}
