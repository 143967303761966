import { useContext } from "react";
import styles from "./styles.module.css";
import PopUpLayout from "../utils/Layout/PopUpLayout";
import TextInput from "../utils/TextInput";
import { useFormik } from "formik";
import * as yup from "yup";
import ImageUpload from "../utils/ImageUpload";
import categoryService from "../../services/categoryService";
import loadingContext from "../../context/loadingContext";
import { isUrl } from "../../helpers/commonHelperFunctions";

export default function AddCategory({ category, closeHandler, onSuccess }) {
  const { setIsLoading } = useContext(loadingContext);

  const validationSchema = yup.object({
    image: yup.mixed(),
    name: yup.string().required("Name is required"),
    description: yup.string().required("Description is required"),
  });

  //formik initialisation
  const formik = useFormik({
    initialValues: category,
    validationSchema,
    onSubmit: submitHandler,
    validateOnChange: false,
  });

  //popup closer
  const closeDialog = () => {
    formik.setValues({
      name: "",
      description: "",
      image: "",
    });
    closeHandler();
  };

  // To submit form
  async function submitHandler(values, helperFunctions) {
    return category.id
      ? updateHandler(values, helperFunctions)
      : createHandler(values, helperFunctions);
  }

  async function createHandler(values, { setFieldError, resetForm }) {
    setIsLoading(true);
    try {
      const data = {
        name: values.name,
        description: values.description,
      };
      const category = await categoryService.addCategory(data);
      if (values.image) {
        await categoryService.addCategoryImage(category.id, values.image);
      }
      onSuccess();
      resetForm();
      closeDialog();
    } catch (err) {
      console.error(err);
      setFieldError("name", err.message);
    }
    setIsLoading(false);
  }

  async function updateHandler(values, { setFieldError, resetForm }) {
    setIsLoading(true);
    try {
      const data = {
        name: values.name,
        description: values.description,
      };
      await categoryService.updateCategory(category.id, data);
      if (values.image && !isUrl(values.image)) {
        await categoryService.addCategoryImage(category.id, values.image);
      }
      onSuccess();
      resetForm();
      closeDialog();
    } catch (err) {
      console.error(err);
      setFieldError("name", err.message);
    }
    setIsLoading(false);
  }

  return (
    <PopUpLayout>
      <div className={styles.title}>Add Category</div>
      <ImageUpload formik={formik} />
      <TextInput
        name="name"
        label="Category Name"
        placeholder="Type your Category Name"
        formikObj={formik}
      />
      <TextInput
        name="description"
        label="Description"
        placeholder="Type your product description"
        formikObj={formik}
      />
      <div className={styles.btnWrapper}>
        <button type="reset" className={styles.closeBtn} onClick={closeDialog}>
          Cancel
        </button>
        <button
          type="button"
          className={styles.submitBtn}
          onClick={formik.handleSubmit}
        >
          Submit
        </button>
      </div>
    </PopUpLayout>
  );
}
