import NavBar from "../NavBar";
import styles from "./styles.module.css";
import { Outlet } from "react-router-dom";
import useAuthUser from "../useAuthUser";
import { Navigate } from "react-router-dom";
import Loader from "../Loader";

function ConsoleLayout() {
  const isAuthenticated = useAuthUser();
  return isAuthenticated ? (
    <>
      <Loader />
      <div className={styles.ContentsWrapper}>
        <div className={styles.NavbarWrapper}>
          <NavBar />
        </div>
        <div className={styles.ContentsArea}>
          <Outlet />
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/user/login" />
  );
}

export default ConsoleLayout;
