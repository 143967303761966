import BackendService from "./BackendService";

const addItem = (data) => {
  return BackendService.post(`/products`, data);
};

const getItemsByCategory = (id) => {
  return BackendService.get(`/products`, {}, { categoryId: id });
};

const getItem = (id) => {
  return BackendService.get(`/products/${id}`);
};

const deleteItem = (id) => {
  return BackendService.destroy(`/products/${id}`);
};

const updateItem = (id, data) => {
  return BackendService.patch(`/products/${id}`, data);
};

const addItemImage = (id, file) => {
  const formData = new FormData();
  formData.append("file", file);
  return BackendService.post(`/products/${id}/image`, formData);
};

const itemService = {
  addItem,
  getItemsByCategory,
  getItem,
  deleteItem,
  updateItem,
  addItemImage,
};
export default itemService;
