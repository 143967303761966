import styles from "./styles.module.css";
import * as React from "react";
import { Draggable } from "react-beautiful-dnd";

export default function OrderCard({ title, index, element, setSelectedOrder }) {
  return (
    <Draggable draggableId={title} index={index}>
      {(provided) => (
        <div
          className={styles.orderCardWrapper}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          onClick={() => {
            setSelectedOrder(element);
          }}
        >
          <div className={styles.cardTitle}>Order {element.orderNo}</div>
          <div className={styles.cardContent}>
            <div className={styles.PersonalDetailsWrapper}>
              <div className={styles.personalDetails}>
                <span className={styles.name}>
                  Mr/Mrs : {element.customer.name}
                </span>
                <span className={styles.name}>Order Id : {element.id}</span>
                <span className={styles.address}>{element.address}</span>
                <span className={styles.phone}>{element.mobile}</span>
              </div>
              {/* <img className={styles.profileImg} src={profile} alt="" /> */}
            </div>
            <div className={styles.descWrapper}>
              <span className={styles.descTitle}>Description</span>
              <span className={styles.desc}>{element.description}</span>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
}
